import dynamic from 'next/dynamic';
export default dynamic(function() {
    return import('.');
}, {
    loadableGenerated: {
        webpack: function() {
            return [
                require.resolveWeak(".")
            ];
        }
    },
    ssr: false
});
