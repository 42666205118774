import { useEffect } from 'react';
import nprogress from 'nprogress';
export default function useNProgress(router) {
    useEffect(function() {
        router.events.on('routeChangeStart', function() {
            nprogress.start();
        });
        router.events.on('routeChangeComplete', function() {
            nprogress.done();
        });
        router.events.on('routeChangeError', function() {
            nprogress.done();
        });
        return function() {
            router.events.off('routeChangeStart', function() {
                nprogress.start();
            });
            router.events.off('routeChangeComplete', function() {
                nprogress.done();
            });
            router.events.off('routeChangeError', function() {
                nprogress.done();
            });
        };
    }, []);
    return null;
};
